import { Controller } from "@hotwired/stimulus"

// Follow Omise payment amount
const FALLBACK_PRICE = 1000.0
const LOCALE = "th-TH"
const LOCALE_OPTIONS = {
  minimumFractionDigits: 2,
}

export default class extends Controller {
  static targets = [
    "resellForm",
    "formLoading",
    "formEmpty",
    "formReady",
    "inputState",
    "confirmState",
    // -- Input State --
    "availableOrders",
    "availableTickets",
    // Price Input elements
    "priceSingleSummary",
    "priceRangeSummary",
    "priceRangeMinText",
    "priceRangeMaxText",
    "priceInput",
    "priceHelperText",
    // Summary Section elements
    "netPriceText",
    "breakdownSellPrice",
    "breakdownFee",
    "breakdownNetPrice",
    "nextStateButton",
    // -- Confirm State --
    "termsContainer",
    "termsCheckbox",
    "submitFormButton",
  ]

  /**
   * This values parsing from controller @variables
   * orders - @eventpop_seller_orders
   * resellEvent - @resell_event
   * newTicket - @ticket
   */
  static values = {
    orders: Array,
    resellEvent: Object,
    newTicket: Object,
  }

  // Class Properties
  selectedOrder
  selectedTicket
  resellPriceDecimal = 0.0
  eventMinimumListPriceDecimal = 0.0
  eventMaximumListPriceDecimal = 0.0
  ticketTypeGroupMinimumListPriceDecimal
  ticketTypeGroupMaximumListPriceDecimal
  netPriceDecimal = 0.0
  feeDecimal = 0.0
  allowTicketTypeIds = []

  // Lifecycle
  connect() {
    this.resellPriceDecimal = parseFloat(String(this.newTicketValue.price))
    this.feeDecimal = parseFloat(
      String(this.resellEventValue.settings.fee_percentage / 100)
    )
    this.eventMinimumListPriceDecimal = parseFloat(
      String(this.resellEventValue.minimum_list_price_satangs / 100)
    )
    this.eventMaximumListPriceDecimal = parseFloat(
      String(this.resellEventValue.maximum_list_price_satangs / 100)
    )

    // TODO: Check order.tickets is not empty also
    if (this.ordersValue.length) {
      this.setAllowTicketTypeIds()
      this.setAvailableOrders()
      this.updateSummarySection()
      this.showFormTarget(this.formReadyTarget)
    } else {
      this.showFormTarget(this.formEmptyTarget)
    }
  }

  showFormTarget(target) {
    this.formLoadingTarget.classList.add("hidden")
    target.classList.remove("hidden")
    target.classList.add("flex")
  }

  setAllowTicketTypeIds() {
    this.allowTicketTypeIds = this.resellEventValue.settings.ticket_type_groups.map(group => {
      return group.eventpop_ticket_type_ids
    }).flat()
  }

  // Dataflow Functions
  setAvailableOrders() {
    // Default blank options
    const selectOptions = [this.availableOrdersTarget.options[0].outerHTML]

    this.ordersValue
      .filter(order => {
        return order.tickets.some(ticket => {
          return ticket.resell_status === "available" && this.allowTicketTypeIds.includes(ticket.ticket_type.id)
        })
      })
      .forEach(order => {
        const title = `${order.event.title} | ${order.order_number}`
        selectOptions.push(`<option value="${order.id}">${title}</option>`)
      })

    this.availableOrdersTarget.innerHTML = selectOptions.join("\n")
  }

  setAvailableTickets() {
    // Default blank options
    const selectOptions = [this.availableTicketsTarget.options[0].outerHTML]

    if (!this.selectedOrder) {
      this.availableTicketsTarget.innerHTML = selectOptions.join("\n")
      this.availableTicketsTarget.disabled = true
      return
    }

    this.selectedOrder.tickets
      .filter(ticket => ticket.resell_status === "available" && this.allowTicketTypeIds.includes(ticket.ticket_type.id))
      .forEach(ticket => {
        const title = `${ticket.ticket_type.name} [${ticket.reference_code}]`
        selectOptions.push(`<option value="${ticket.id}">${title}</option>`)
      })

    this.availableTicketsTarget.innerHTML = selectOptions.join("\n")
    this.availableTicketsTarget.disabled = false
  }

  findOrder(orderId) {
    return this.ordersValue.find(order => order.id === orderId)
  }

  findTicket(ticketId) {
    return this.selectedOrder.tickets.find(ticket => ticket.id === ticketId)
  }

  updateSummarySection() {
    this.netPriceDecimal =
      this.resellPriceDecimal - this.resellPriceDecimal * this.feeDecimal

    this.breakdownSellPriceTarget.textContent =
      this.resellPriceDecimal.toLocaleString(LOCALE, LOCALE_OPTIONS)
    this.breakdownFeeTarget.textContent = (
      this.resellPriceDecimal * this.feeDecimal
    ).toLocaleString(LOCALE, LOCALE_OPTIONS)
    this.breakdownNetPriceTarget.textContent =
      this.netPriceDecimal.toLocaleString(LOCALE, LOCALE_OPTIONS)

    this.netPriceTextTargets.forEach(target => {
      return (target.textContent = this.netPriceDecimal.toLocaleString(
        LOCALE,
        LOCALE_OPTIONS
      ))
    })
  }

  async fetchTicketTypePriceRange() {
    const ticket_type_id = this.selectedTicket.ticket_type.id
    const apiUrl = `/e/${this.resellEventValue.id}/ticket_type_price_range/${ticket_type_id}/`

    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
  }

  // Validation Functions
  checkFormValidity() {
    // Check form is valid
    if (
      this.selectedOrder &&
      this.selectedTicket &&
      this.resellPriceDecimal >= this.getMinimumListPrice() &&
      this.resellPriceDecimal <= this.getMaximumListPrice()
    ) {
      this.nextStateButtonTarget.disabled = false
      this.nextStateButtonTarget.classList.remove("disabled")
      return true
    } else {
      this.nextStateButtonTarget.disabled = true
      this.nextStateButtonTarget.classList.add("disabled")
      return false
    }
  }

  // Action Functions
  handleOrderChange(event) {
    this.selectedOrder = this.findOrder(parseInt(event.target.value))
    this.selectedTicket = null
    this.priceInputTarget.disabled = true
    this.priceInputTarget.value = FALLBACK_PRICE

    this.hidePriceRangeHelperText()
    this.checkFormValidity()
    this.setAvailableTickets()
  }

  async handleTicketChange(event) {
    this.selectedTicket = this.findTicket(parseInt(event.target.value))

    if (this.selectedTicket) {
      await this.handlePriceRangeHelperText()

      const minimumListPrice = this.getMinimumListPrice()
      const maximumListPrice = this.getMaximumListPrice()

      this.priceInputTarget.value = Math.max(
        this.selectedTicket.ticket_type.price,
        minimumListPrice
      )
      this.resellPriceDecimal = Math.max(
        this.selectedTicket.ticket_type.price,
        minimumListPrice
      )
      this.priceInputTarget.disabled = false

      this.priceInputTarget.min = this.priceHelperTextTarget.innerHTML =
        I18n.t(
          "activerecord.errors.models.ticket.attributes.price.greater_than_or_equal_to",
          {
            min: new Intl.NumberFormat(I18n.locale, {
              currency: "THB",
              style: "currency",
            }).format(minimumListPrice),
          }
        ) +
        " " +
        I18n.t(
          "activerecord.errors.models.ticket.attributes.price.less_than_or_equal_to",
          {
            max: new Intl.NumberFormat(I18n.locale, {
              currency: "THB",
              style: "currency",
            }).format(maximumListPrice),
          }
        )
    } else {
      this.priceInputTarget.value = FALLBACK_PRICE
      this.resellPriceDecimal = FALLBACK_PRICE
      this.priceInputTarget.disabled = true
      this.priceInputTarget.min = FALLBACK_PRICE
    }

    this.updateSummarySection()
    this.checkFormValidity()
  }

  async handlePriceRangeHelperText() {
    // Fetch the latest min price from server
    const data = await this.fetchTicketTypePriceRange()

    const minPrice = parseFloat(data?.min_price)
    const maxPrice = parseFloat(data?.max_price)

    this.ticketTypeGroupMinimumListPriceDecimal = data?.minimum_list_price
    this.ticketTypeGroupMaximumListPriceDecimal = data?.maximum_list_price

    if (!isNaN(minPrice) && !isNaN(maxPrice)) {
      if (data?.is_equal) {
        this.priceSingleSummaryTarget.classList.replace("hidden", "inline-flex")
        this.priceRangeSummaryTarget.classList.replace("inline-flex", "hidden")
      } else {
        this.priceSingleSummaryTarget.classList.replace("inline-flex", "hidden")
        this.priceRangeSummaryTarget.classList.replace("hidden", "inline-flex")
        this.priceRangeMaxTextTarget.textContent = maxPrice.toLocaleString(
          LOCALE,
          LOCALE_OPTIONS
        )
      }

      this.priceRangeMinTextTargets.forEach(
        target =>
          (target.textContent = minPrice.toLocaleString(LOCALE, LOCALE_OPTIONS))
      )
    }
  }

  hidePriceRangeHelperText() {
    this.priceSingleSummaryTarget.classList.replace("inline-flex", "hidden")
    this.priceRangeSummaryTarget.classList.replace("inline-flex", "hidden")
    this.priceRangeMinTextTargets.forEach(target => {
      return (target.textContent = "----.--")
    })
    this.priceRangeMaxTextTargets.textContent = "----.--"
  }

  handlePriceChange(event) {
    this.resellPriceDecimal = event.target.value

    if (event.target.value < 0) {
      this.priceInputTarget.value = this.getMinimumListPrice()
      this.resellPriceDecimal = this.getMinimumListPrice()
      return
    }

    if (this.getMaximumListPrice() !== 'Nan' && event.target.value > this.getMaximumListPrice()) {
      this.priceInputTarget.value = this.getMaximumListPrice()
      this.resellPriceDecimal = this.getMaximumListPrice()
      return
    }

    if (
      event.target.value < this.getMinimumListPrice() ||
      event.target.value > this.getMaximumListPrice() ||
      isNaN(event.target.value)
    ) {
      this.priceInputTarget.classList.add("invalid")
      this.priceHelperTextTarget.classList.remove("hidden")
    } else {
      this.priceInputTarget.classList.remove("invalid")
      this.priceHelperTextTarget.classList.add("hidden")
    }

    this.updateSummarySection()
    this.checkFormValidity()
  }

  handleTermsCheckboxChange() {
    this.submitFormButtonTarget.disabled = !this.termsCheckboxTarget.checked

    if (this.termsCheckboxTarget.checked) {
      this.submitFormButtonTarget.classList.remove("disabled")
    } else {
      this.submitFormButtonTarget.classList.add("disabled")
    }
  }

  // Handling State Functions
  enableInputs(mode) {
    const INPUT_TARGET = [
      this.availableOrdersTarget,
      this.availableTicketsTarget,
      this.priceInputTarget,
    ]

    INPUT_TARGET.forEach(target => {
      target.disabled = mode === "add"
      target.classList[mode]("readonly")
    })
  }

  goToConfirmState() {
    if (!this.checkFormValidity()) return

    this.inputStateTarget.classList.replace("flex", "hidden")
    this.confirmStateTarget.classList.replace("hidden", "flex")
    this.enableInputs("add")
    this.termsContainerTarget.scrollIntoView({ behavior: "smooth" })
  }

  goBackInputState() {
    this.confirmStateTarget.classList.replace("flex", "hidden")
    this.inputStateTarget.classList.replace("hidden", "flex")
    this.enableInputs("remove")
    this.availableOrdersTarget.scrollIntoView({ behavior: "smooth" })
  }

  submitForm() {
    // Enable inputs before submit
    this.enableInputs("remove")
    this.priceInputTarget.value = this.resellPriceDecimal
    this.resellFormTarget.submit()
  }

  getMinimumListPrice() {
    if (this.ticketTypeGroupMinimumListPriceDecimal) {
      return this.ticketTypeGroupMinimumListPriceDecimal
    }

    return this.eventMinimumListPriceDecimal
  }

  getMaximumListPrice() {
    if (this.ticketTypeGroupMaximumListPriceDecimal) {
      return this.ticketTypeGroupMaximumListPriceDecimal
    }

    return this.eventMaximumListPriceDecimal
  }
}
