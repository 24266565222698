import { Controller } from "@hotwired/stimulus"

let omisePromise
function ensureOmiseLoaded() {
  return omisePromise ??= new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = "https://cdn.omise.co/omise.js";
    script.onload = () => resolve(window.OmiseCard);
    script.onerror = (e) => {
      reject(e)
      omisePromise = undefined
    };
    script.async = true;
    document.head.appendChild(script);
  })
}

export default class extends Controller {
  static targets = [
    "omiseJsLoading",
    "omiseJsReady",
    "omiseJsError",
    "paymentButton",
    "reloadPageButton",
    "defaultPaymentState",
    "processingPaymentState",
  ]

  static values = {
    omiseFormId: String,
    publicKey: String,
    amount: Number,
    currency: String
  }

  // Class properties
  OmiseCard
  omiseForm
  omiseLoadingInterval

  async connect() {
    try {
      this.OmiseCard = await ensureOmiseLoaded()
      this.OmiseCard.configure({
        publicKey: this.publicKeyValue
      })

      this.omiseForm = document.getElementById(this.omiseFormIdValue)
    } catch (e) {
      console.error(e)
    }

    this.omiseLoadingInterval = setInterval(() => {
      if (this.OmiseCard) {
        this.omiseJsLoadingTarget.classList.replace("flex", "hidden")
        this.omiseJsReadyTarget.classList.replace("hidden", "flex")
      } else {
        this.omiseJsLoadingTarget.classList.replace("flex", "hidden")
        this.omiseJsErrorTarget.classList.replace("hidden", "flex")
      }
    }, 1000)
  }

  disconnect() {
    clearInterval(this.omiseLoadingInterval)
  }

  checkout() {
    clearInterval(this.omiseLoadingInterval)

    let interval

    // Change payment button state
    this.paymentButtonTarget.disabled = true
    this.paymentButtonTarget.classList.add("disabled")
    this.defaultPaymentStateTarget.classList.replace("inline-flex", "hidden")
    this.processingPaymentStateTarget.classList.replace("hidden", "inline-flex")

    this.OmiseCard.open({
      amount: this.amountValue,
      currency: this.currencyValue,
      defaultPaymentMethod: "credit_card",
      // Ref: https://docs.opn.ooo/omise-js#supported-payment-methods-for-pre-built-form
      otherPaymentMethods: "credit_card,promptpay,alipay",
      onCreateTokenSuccess: (nonce) => {
        if (nonce.startsWith("tokn_")) {
          this.omiseForm.omise_token.value = nonce;
        } else {
          this.omiseForm.omise_source.value = nonce;
        }

        this.omiseForm.submit()
      },
      onFormClosed: () => {
        this.paymentButtonTarget.disabled = false
        this.paymentButtonTarget.classList.remove("disabled")
        this.defaultPaymentStateTarget.classList.replace("hidden", "inline-flex")
        this.processingPaymentStateTarget.classList.replace("inline-flex", "hidden")
        clearInterval(interval)
      }
    })

    // Scan page for missing iframe
    interval = setInterval(() => {
      // After 5 seconds, if the iframe is not found,
      // show the reload page button
      if (!document.getElementById("omise-checkout-iframe-app")) {
        this.paymentButtonTarget.classList.add("hidden")
        this.reloadPageButtonTarget.classList.remove("hidden")
        clearInterval(interval)
      }
    }, 1500)
  }
}
