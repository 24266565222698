import { I18n } from "i18n-js"
import locales from "./locales.json"
window.I18n = new I18n(locales)
window.I18n.locale = document.documentElement.lang

import "@hotwired/turbo-rails"
import "./controllers"

import "flowbite/dist/flowbite.turbo.min.js"
import "./icons/lucide"
