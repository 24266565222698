import { Controller } from "@hotwired/stimulus"
import { JSONEditor } from "@json-editor/json-editor/src/core" // https://github.com/json-editor/json-editor/issues/1470

export default class extends Controller {
  static values = {
    formId: String,
    formName: String,
    jsonEditorSchema: Object,
    jsonData: Object,
  }

  initialize() {}

  connect() {
    if (this.formIdValue === undefined) {
      console.error("Attribute data-admin-form-form-id-value is required")
      return
    }

    const editorEl = document.querySelector(
      `#${this.formIdValue} > .json-editor`
    )
    this.createEditor(editorEl)
  }

  createEditor(editorEl) {
    // https://www.npmjs.com/package/@json-editor/json-editor#options
    const options = {
      schema: this.jsonEditorSchemaValue,
      theme: "tailwind",
      disable_collapse: true,
      disable_properties: true,
      prompt_before_delete: false,
      show_opt_in: true,
      form_name_root: this.formNameValue,
    }

    const editor = new JSONEditor(editorEl, options)

    editor.on("ready", () => {
      if (Object.keys(this.jsonDataValue).length !== 0) {
        editor.setValue(this.jsonDataValue)
      }
    })
  }
}
